import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { debounce } from 'lodash';
import { reaction } from 'mobx';

import { Highcharts } from 'core/components';

@observer
class BaseHighchartsNonStyledDataview extends Component {
  static propTypes = {
    $app: PropTypes.any.isRequired,
    $dataviews: PropTypes.any.isRequired
  };

  visible = false;

  componentDidMount() {
    const { $app, $dataviews } = this.props;
    this.darkThemeDisposer = reaction(
      () => $app.darkThemeEnabled,
      () => setTimeout(() => this.redraw(), 100)
    );

    $dataviews.register(this);
  }

  componentDidUpdate(prevProps) {
    const { data, width } = this.props;
    if (this.chart) {
      if (data !== prevProps.data) {
        this.renderData();
      }

      // Used for Observation Deck widget resize
      if (prevProps.width && width !== prevProps.width) {
        this.reflow();
      }
    }
  }

  componentWillUnmount() {
    const { $dataviews } = this.props;

    if (this.resizeListener) {
      window.removeEventListener('resize', this.resizeListener);
    }
    if (this.darkThemeDisposer) {
      this.darkThemeDisposer();
      this.darkThemeDisposer = null;
    }

    $dataviews.unregister(this);
  }

  handleObservation = (observables) => {
    const [observable] = observables;
    const { isIntersecting } = observable;

    this.visible = isIntersecting;
  };

  get containerStyle() {
    return { width: '100%' };
  }

  // abstract
  redraw() {
    console.warn('BaseHighchartsNonStyledDataview.redraw not implemented');
  }

  // abstract
  renderData() {
    console.warn('BaseHighchartsNonStyledDataview.renderData not implemented');
  }

  // setup container resize observer
  chartCallback = (chart) => {
    const { stickyPointer } = this.state;

    this.chart = chart.container ? chart : null;
    this.el = chart.container;

    this.resizeListener = debounce(() => this.reflow(), 200);
    window.addEventListener('resize', this.resizeListener);

    this.renderData();

    const chartObserver = new IntersectionObserver(
      (observables) => {
        this.handleObservation(observables);
      },
      { threshold: 1 }
    );

    chartObserver.observe(this.el);

    if (stickyPointer && this.chart && this.chart.pointer) {
      this.chart.pointer.reset = function reset() {};
    }
  };

  reflow() {
    if (this.chart && this.chart.hasRendered && this.el) {
      this.chart.setSize(this.el.parentElement.offsetWidth, this.el.parentElement.offsetHeight);
      this.chart.reflow();
    }
  }

  render() {
    const { chartOptions, highlightPointsByEvent } = this.state;

    return (
      <Highcharts
        options={chartOptions}
        callback={this.chartCallback}
        styledMode={false}
        highlightPointsByEvent={highlightPointsByEvent}
        containerProps={{ style: this.containerStyle }}
      />
    );
  }
}

export default BaseHighchartsNonStyledDataview;
